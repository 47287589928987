import BaseMixin from './base.mixin'
import LocalStorageMixin from '@/shared/mixins/local-storage.mixin'

const mixin = {
  mixins: [
    BaseMixin,
    LocalStorageMixin
  ],
  methods: {
    logoutUser () {
      const user = this.getUser()
      if (user) {
        this.$socket.emit('logout')
      }
      this.removeUser()
      const nextRoute = 'login'
      if (this.$router.currentRoute.name !== nextRoute) {
        this.$router.push({ name: nextRoute })
      }
      sessionStorage.removeItem('isValidatedPhysician')
      sessionStorage.removeItem('certificateValidation')
    },
    isAuthenticated () {
      return this.getUser() !== null
    },
    isEmergencySource () {
      return localStorage.getItem('isEmergency') === 'true'
    },
    setEmergencySource (value) {
      this.saveInLocalStorage('isEmergency', value)
    },
    getFirstRole () {
      const user = this.getFromLocalStorage('user')
      return user ? user.roles[0] : ''
    },
    getUser () {
      return this.getFromLocalStorage('user')
    },
    getFullName () {
      const user = this.getFromLocalStorage('user')
      return user ? user.fullname : ''
    },
    getFonteId () {
      const user = this.getFromLocalStorage('user')
      return user ? user.source[0].id : ''
    },
    saveUser (user) {
      this.saveInLocalStorage('user', user)
    },
    removeUser () {
      this.removeFromLocalStorage('user')
      this.removeFromLocalStorage('ngStorage-auth')
      this.removeFromLocalStorage('hasSeenPopUp')
    },
    isIntercomPermited () {
      const roleName = this.getFirstRole()
      const permittedRoles = ['source', 'physician']
      return permittedRoles.includes(roleName)
    },
    isFonte () {
      const roleName = this.getFirstRole()
      return roleName === 'source'
    },
    getHomePage () {
      const roleName = this.getFirstRole()
      switch (roleName) {
        case 'source':
          return 'exames'

        case 'physician':
          if (this.getUser().physician.listExams) {
            return 'medico/laudar-radio'
          } else if (this.getUser().physician.onlyReports) {
            return '/medico/laudar'
          } else {
            return '/medico'
          }

        case 'admin':
        case 'adminSource':
          return 'admin'

        default:
          return 'login'
      }
    },
    async getValidateCertificate (forceUpdate = false) {
      const storedValidation = sessionStorage.getItem('certificateValidation')

      if (!forceUpdate && storedValidation) {
        const parsedValidation = JSON.parse(storedValidation)
        if (parsedValidation.token && parsedValidation.tokenExpiration) {
          const tokenExpiration = new Date(parsedValidation.tokenExpiration)

          if (new Date() < tokenExpiration) {
            return parsedValidation
          }
        }
      }
      const request = await this.requestGet('core/PtmSigner/ValidateCertificate')
      if (request.tokenExpiration) {
        request.tokenExpiration = new Date(request.tokenExpiration)
      }

      sessionStorage.setItem('certificateValidation', JSON.stringify(request))
      return request
    },
    async postSendQrCode (code) {
      const url = `core/PtmSigner/ValidateQrCode/${code}`
      const res = await this.requestPost(url)
      const certificateData = res.data.data
      sessionStorage.setItem('certificateValidation', JSON.stringify(certificateData))
      return certificateData
    },
    async disablePhysicianSource () {
      const url = `core/PtmSigner/DisableSources`
      const res = await this.requestPost(url)
      return res.data.data
    }
  }
}

export default mixin
