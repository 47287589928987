import AuthMixin from '@/shared/mixins/auth.mixin'
import DateMixin from '@/shared/mixins/date.mixin'
import PhysicianListMixin from '@/shared/mixins/physician-list.mixin'
import ItemList from '@/components/item-list/index.vue'

export default {
  name: 'pacientes',
  components: {
    ItemList
  },
  mixins: [
    AuthMixin,
    DateMixin,
    PhysicianListMixin
  ],
  props: [],
  data () {
    return {
      patients: [],
      filter: ''
    }
  },
  computed: {
    filteredPatients () {
      let patients = this.patients.map((patient) => ({ ...patient, __id: patient.patientName.substring(0, 1) }))

      if (this.filter) {
        patients = patients.filter(patient => patient.patientName.toLowerCase().indexOf(this.filter.toLowerCase()) > -1)
      }

      return patients.map(patient => ({
        id: patient.__id,
        title: this.capitalize(patient.patientName),
        subhead: this.getFormattedDate(patient.lastReportDate),
        patientId: patient.patientId
      }))
    }
  },
  mounted () {
    this.loadPatients()
  },
  methods: {
    async loadPatients () {
      this.patients = []
      this.$root.$emit('showLoading')
      try {
        this.patients = await this.getExamsReported()
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    pacienteClick (item) {
      this.$router.push({ name: 'paciente-detalhes', params: { id: item.patientId } })
    }
  }
}
