import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    async getExamHeatmap (examId) {
      const url = `/core/Heatmap?examId=${examId}`
      const response = await this.getClient().get(url, {}, true)
      return response.data.data
    },
    async getOpinions (examId) {
      const url = `/core/Heatmap/Opinions?examId=${examId}`
      const response = await this.getClient().get(url, {}, true)
      return response.data.data
    },
    async setOpinion (examId, sctid, agreed, reason) {
      const opinion = { sctid, agreed, reason }
      const url = `/core/Heatmap/Opinions?examId=${examId}`
      const result = await this.requestPost(url, opinion)
      return result.data.data
    }
  }
}

export default mixin
