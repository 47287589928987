import HeatmapMixin from '@/shared/mixins/heatmap.mixin'

export default {
  name: 'exame-heatmap',
  components: {},
  mixins: [
    HeatmapMixin
  ],
  props: [],
  data () {
    return {
      exam: null,
      opinions: {}
    }
  },
  computed: {

  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      try {
        this.$root.$emit('showLoading')
        this.fetchExam()
        this.fetchOpinions()
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    async fetchExam () {
      this.exam = await this.getExamHeatmap(this.$route.params.id)
    },
    async fetchOpinions () {
      const result = await this.getOpinions(this.$route.params.id)
      const opinions = {}
      for (let i = 0; i < result.length; ++i) {
        const opinion = result[i]
        opinions[opinion.sctid] = opinion
      }
      this.opinions = opinions
    },
    async onAgreement ({ prediction, agreed, reason }) {
      try {
        this.$root.$emit('showLoading')
        const opinion = await this.setOpinion(this.$route.params.id, prediction.id, agreed, reason)
        this.opinions[prediction.id] = opinion
      } finally {
        this.$root.$emit('hideLoading')
      }
    }
  }
}
