import routes from '@/router/routes'
import AuthMixin from '@/shared/mixins/auth.mixin'

export default {
  name: 'login',
  components: {},
  mixins: [
    AuthMixin
  ],
  props: {
    isPiauiDomain: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      logo: this.getLogoByURL(),
      authRoute: import.meta.env.VITE_AUTH_ROUTE,
      urlApi: import.meta.env.VITE_URL_API,
      routes,
      recaptchaPublicKey: import.meta.env.VITE_RECAPTCHA_PUBLIC_KEY
    }
  },
  mounted () {
    localStorage.removeItem("selectedSource")

    if (
      this.logo.includes('logo-piaui-saude-digital.png') &&
      window.location.hostname.match(/^(dev|hml|)\.?laudos\.piauisaudedigital\.com\.br/)
    ) {
      const logoElement = this.$el.querySelector('.logo')
      if (logoElement) {
        logoElement.classList.add('piaui-logo')
      }
    }

    const role = this.getFirstRole()
    if (role) {
      this.$router.push(`/${this.getHomePage()}`)
    }
    this.$root.$emit('hideLoading')
  },
  methods: {
    getLogoByURL () {
      const url = window.location.hostname
      const piauiRegex = /^(dev|hml|)\.?laudos\.piauisaudedigital\.com\.br/

      if (piauiRegex.test(url)) {
        return '../../assets/img/logo-piaui-saude-digital.png'
      } else {
        return '../../assets/img/logo-portal-telemedicina.png'
      }
    }
  }
}
