import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    capitalize (text) {
      const capWord = (word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`
      return text.toLowerCase().split(' ').map(word => capWord(word)).join(' ')
    },
    async getImpersonate () {
      const url = '/core/Queue/Administrator/Impersonate'
      const response = await this.getClient().get(url, {}, true)
      return response.data.data
    },
    async getExamsReported () {
      const url = '/core/Physician/Exams/Reported'
      const response = await this.getClient().get(url, {}, true)
      return response.data.data
    },
    async getPatientDetails (patientId) {
      const url = `/core/Patients/Details/${patientId}`
      const response = await this.getClient().get(url, {}, true)
      return response.data.data
    },
    async getPatientExam (patientId, examId) {
      const url = `/core/Patients/${patientId}/Exam/${examId}`
      const response = await this.getClient().get(url, {}, true)
      return response.data.data
    }
  }
}

export default mixin
