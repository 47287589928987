import BaseMixin from './base.mixin'

import CONSTANTS from '@/shared/constants/constants'

const STATUS = {
  [CONSTANTS.REPORT_STATUS.NAO_SELECIONADO]: 'Não identificado',
  [CONSTANTS.REPORT_STATUS.NORMAL]: 'Normal',
  [CONSTANTS.REPORT_STATUS.ALTERADO]: 'Alterado',
  [CONSTANTS.REPORT_STATUS.REPETIR]: 'Repetir'
}

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    getReportStatusName (value) {
      if (value in STATUS) {
        return STATUS[value]
      }
      return 'Não consta'
    },
    getReportStatusClass (value) {
      if (value === CONSTANTS.REPORT_STATUS.ALTERADO) {
        return 'danger'
      }
      return []
    }
  }
}

export default mixin
