import AuthMixin from '@/shared/mixins/auth.mixin'
import DateMixin from '@/shared/mixins/date.mixin'
import PhysicianListMixin from '@/shared/mixins/physician-list.mixin'
import ItemList from '@/components/item-list/index.vue'

export default {
  name: 'exames-pendentes',
  components: {
    ItemList
  },
  mixins: [
    AuthMixin,
    DateMixin,
    PhysicianListMixin
  ],
  props: [],
  data () {
    return {
      exams: [],
      filter: ''
    }
  },
  computed: {
    doctorName () {
      return `${this.$i18n.t('doctor_abbreviation') + this.getUser().fullname}`
    },
    filteredExams () {
      let exams = this.exams.map((exam, index) => ({ ...exam, __id: `${index + 1}` }))

      if (this.filter) {
        exams = exams.filter(exam => exam.patientName.toLowerCase().indexOf(this.filter.toLowerCase()) > -1)
      }

      return exams.map(exam => ({
        id: exam.__id,
        title: this.capitalize(exam.patientName),
        subhead: this.getFormattedDate(exam.openingDate)
      }))
    }
  },
  mounted () {
    this.loadExams()
  },
  methods: {
    async loadExams () {
      this.exams = []
      this.$root.$emit('showLoading')
      try {
        this.exams = await this.getImpersonate()
      } finally {
        this.$root.$emit('hideLoading')
      }
    }
  }
}
