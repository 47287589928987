import ExamSourceMixin from '@/shared/mixins/exam-source.mixin'
import support from '@/components/support/index.vue'
import billing from '@/components/billing/index.vue'
import AuthMixin from '@/shared/mixins/auth.mixin'
import routes from '@/router/routes'
import svgWifiIcon from '../../assets/svg/wifi-logo.svg'
import svgWifiOffIcon from '../../assets/svg/wifi-off.svg'
import { mapGetters, mapActions } from 'vuex'
import finantialAlert from '@/components/finantial-alert/index.vue'
import emojis from "../../assets/img/emojis.png"
import CONSTANTS from '@/shared/constants/constants'

export default {
  name: 'utilities',
  components: {
    billing,
    support,
    finantialAlert
  },
  mixins: [
    ExamSourceMixin,
    AuthMixin
  ],
  data () {
    return {
      logo: this.getLogoByURL(),
      emojis,
      userLogo: null,
      userName: '',
      showNavbar: false,
      showBilling: false,
      periodItems: [],
      showLoadingModal: false,
      messageLoadingModal: this.$i18n.t('loading.default'),
      showLoadingBar: false,
      showBlockPage: false,
      messageBlockPage: this.$i18n.t('tab.extra_tabs_open'),
      user: {},
      oldTab: null,
      connectionInterval: null,
      connectionAlert: false,
      connectionOffAlert: false,
      connectionTooltip: this.$i18n.t('connectionTooltip'),
      wifiIcon: svgWifiIcon,
      wifiOffIcon: svgWifiOffIcon,
      userProfile: '',
      sources: [],
      selectedSource: null,
    }
  },
  computed: {
    tab () {
      const endpoint = this.$route.path
      return endpoint
    },
    menu() {
      const role = this.getFirstRole()
      const user = this.getUser()
      const physician = user.physician || {}
      const isPhysician = role === CONSTANTS.ROLES.PHYSICIAN
      const isOnlyReports = physician?.onlyReports === true
      const isListExams = physician?.listExams === true

      if (isPhysician && !isOnlyReports && !isListExams) {
        this.$root.$emit('toast', { message: this.$t('no-physician-function'), type: 'warning' })
        this.logoutUser()
        return []
      }

      const optionsRoutes = routes
      .filter(item => item.meta.roles && item.meta.roles.includes(role) && item.menu)
      .filter(item => {
        if (!isPhysician && item.meta.roles && !item.meta.roles.includes(role) && item.menu){
          return true
        }
        if( ! isOnlyReports && ! isListExams ) {
          return true
        }
        if( ! ['/medico/laudar', '/medico/laudar-radio'].includes(item.path) && (isOnlyReports || isListExams) ) {
          return false
        }
        if (item.path === '/medico/laudar') return isOnlyReports
        if (item.path === '/medico/laudar-radio') return isListExams
        return true
      })
      .filter(item => item.path === '/medico/laudar' || item.path === '/medico/laudar-radio')
      .map(item => ({
        label: item?.menu?.name,
        route: item.path,
        isActive: this.$route.path === item.path
      }))
        return optionsRoutes
      }
  },
  
  mounted () {
    this.initSelectSources()

    this.$socket.connect()
    this.showUtilities()
    this.$root.$on('showNavbarLoading', () => {
      this.showLoadingBar = true
    })
    this.$root.$on('hideNavbarLoading', () => {
      this.showLoadingBar = false
    })
    this.$root.$on('showLoading', (message) => {
      this.showLoadingModal = true
      this.messageLoadingModal = message ||
        this.$i18n.t('loading.default')
    })
    this.$root.$on('hideLoading', () => {
      this.showLoadingModal = false
    })
    this.connectionInterval = setInterval(() => {
      !navigator.onLine ? this.connectionOffAlert = true : this.connectionOffAlert = false
      if (navigator.connection) {
        const connectionQuality = navigator.connection.downlink
        connectionQuality <= 4 && navigator.onLine ? this.connectionAlert = true : this.connectionAlert = false
      }
    }, 15000)
  },
  beforeDestroy () {
    clearInterval(this.connectionInterval)
  },
  methods: {
    ...mapGetters([
      'getCurrentTab'
    ]),
    ...mapActions([
      'setCurrentTab'
    ]),
    showSourceSelector() {
      return this.isFonte() && this.sources?.length > 1
    },
    initSelectSources () {
      new Promise(async (resolve, reject) => {
        try {
          this.sources = [...(await this.getCurrentUserSources()).map(s => {
            s.value = s.description
            return s
          })]
          const role = this.getFirstRole()

          if (role === 'source' && this.sources) {
            this.selectedSource = JSON.parse(localStorage.getItem("selectedSource"))
              || this.sources[0]

            if (!this.sources.map(s => s.id).includes(this.selectedSource.id)) {
              localStorage.removeItem("selectedSource")
              this.selectedSource = this.sources[0]
            }

            localStorage.setItem("selectedSource", JSON.stringify(this.selectedSource))
          } else {
            localStorage.removeItem("selectedSource")
            this.selectedSource = null
          }

          resolve(this.sources)
        } catch (error) {
          reject(error)
        }
      }).then((res) => {})
    },
    changeSource (source) {
      this.selectedSource = source
      localStorage.setItem("selectedSource", JSON.stringify(this.selectedSource))
      // TODO: check this comment
      // this.$root.$emit('selectSource')
      const nextRoute = 'exames'
      if (this.$router.currentRoute.name !== nextRoute) {
        this.$router.push({ name: nextRoute})
      }
      this.$router.go()
    },
    async showUtilities () {
      const routeName = this.$router.currentRoute.name
      if (/login/.test(routeName)) {
        this.hideUtilities()
        return
      }

      if (!this.isAuthenticated()) {
        return
      }
      this.user = this.getUser()
      this.userName = this.user.userName
      this.userProfile = this.user.role.name
      this.showNavbar = true
      // this.onConnected(this.user)

      this.userLogo = null
      if (this.isFonte()) {
        this.userLogo = await this.getLogoUser()

        const key = import.meta.env.VITE_SUBSCRIPTION_APPLICATION_SERVER_KEY
        await navigator.serviceWorker.register('./sw.js')
        const sw = await navigator.serviceWorker.ready
        const subscription = await sw.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: key
        })
        await this.addSubscription(subscription)
      }
    },
    hideUtilities () {
      this.showNavbar = false
    },
    getLogoUser () {
      try {
        return this.getExamSourceLogo()
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    getLogoByURL () {
      const url = window.location.hostname
      const piauiRegex = /^(dev|hml|)\.?laudos\.piauisaudedigital\.com\.br/

      if (piauiRegex.test(url)) {
        return '../../assets/img/logo-piaui-saude-digital.png'
      } else {
        return '../../assets/img/logo-portal-telemedicina.png'
      }
    },
    logout () {
      this.logoutUser()
      localStorage.removeItem('pendencyAlertDismissed')
    },
    isSource () {
      return this.user.role.name === 'source'
    },
    issueBill () {
      window.open('https://telemedicinaportalcliente.cigam.cloud/logincontato', '_blank')
    },
    billing () {
      this.showBilling = true
    }
  },
  watch: {
    $route: function (to, from) {
      if (/login/.test(to.name)) {
        this.hideUtilities()
      }

      if (/login/.test(from.name)) {
        this.showUtilities()
      }

      if (/medico\/laudar/.test(to.name)) {
        this.setCurrentTab(to.name)
      }
    }
  }
}
