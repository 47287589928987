import BaseMixin from './base.mixin'
import DateMixin from './date.mixin'

const mixin = {
  mixins: [
    BaseMixin,
    DateMixin
  ],
  methods: {
    age (birthDate) {
      return `${this.getDifferenceFromToday(birthDate, 'years')} anos`
    },
    cpf (value) {
      try {
        const cpf = value.replace(/[^\d]/g, '')
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      } catch (err) {
        return value
      }
    }
  }
}

export default mixin
