import AuthMixin from '@/shared/mixins/auth.mixin'
import CategoryMixin from '@/shared/mixins/category.mixin'
import DateMixin from '@/shared/mixins/date.mixin'
import ExamFileMixin from '@/shared/mixins/exam-file.mixin'
import ExamStatusMixin from '@/shared/mixins/exam-status.mixin'
import PhysicianListMixin from '@/shared/mixins/physician-list.mixin'

export default {
  name: 'paciente-exame',
  components: {},
  mixins: [
    AuthMixin,
    CategoryMixin,
    DateMixin,
    ExamFileMixin,
    ExamStatusMixin,
    PhysicianListMixin
  ],
  props: [],
  data () {
    return {
      patient: {},
      exam: {},
      pdfUrl: null
    }
  },
  computed: {
    readsAI () {
      const user = this.getUser()
      return user.physician && user.physician.readsAI
    }
  },
  async mounted () {
    await this.loadData()
    await this.setPdfUrl()
  },
  methods: {
    async loadData () {
      this.$root.$emit('showLoading')
      try {
        const data = await this.getPatientExam(this.$route.params.patientId, this.$route.params.examId)
        this.patient = data.patient
        this.exam = data.exam
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    async setPdfUrl () {
      this.pdfUrl = await this.getExamFileById(this.exam.fileId)
    },
    expandHeatmap () {
      const routeData = this.$router.resolve({ name: 'exame-heatmap', params: { id: this.$route.params.examId } })
      window.open(routeData.href, '_blank')
    }
  }
}
