import CategoryMixin from '@/shared/mixins/category.mixin'
import DashboardMixin from '@/shared/mixins/dashboard.mixin'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'exam-chart',
  components: {
    apexchart: VueApexCharts
  },
  mixins: [
    CategoryMixin,
    DashboardMixin
  ],
  props: [],
  data () {
    const today = new Date()

    return {
      startDate: today,
      endDate: today,
      data: {},
      chartOptions: {
        chart: {
          events: {
            updated: this.onChartUpdated
          }
        },
        noData: {
          text: this.$i18n.t('not_found.no_report_found')
        },
        dataLabels: {
          style: {
            fontSize: '24px'
          }
        },
        tooltip: {
          custom: this.getExamChartTooltip
        }
      }
    }
  },
  computed: {
    chartHasData () {
      return this.data.items && Object.keys(this.data.items).length > 0
    },
    chartSeries () {
      if (!this.chartHasData) {
        return [{ data: [] }]
      }
      const data = Object.keys(this.data.items).map(key => {
        const item = this.data.items[key]
        return {
          x: this.getChartXAxisLabel(item),
          y: item.count,
          tooltip: item.specialityName
        }
      })

      return [{ data }]
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      this.data = await this.getReportingStatsChartData(this.startDate, this.endDate)
    },
    onChartUpdated (chartContext, config) {
      if (!this.chartHasData) return false

      const labels = this.getChartLabelElements()
      labels.forEach(this.setIconAndFormatLabel)
    },
    setIconAndFormatLabel (label) {
      const textElement = label.children[0]
      const [ , linkClientId, count ] = textElement.textContent.split('|')

      try {
        const svg = this.convertToDomNode(this.getSvgForLinkClientId(linkClientId))
        this.setLabelChildrenPositions(svg, textElement)
        label.appendChild(svg)
      } catch (err) {
      }

      textElement.textContent = count
    },
    setLabelChildrenPositions (svg, textElement) {
      const tX = parseFloat(textElement.getAttribute('x'))
      const tY = parseFloat(textElement.getAttribute('y'))
      svg.setAttribute('height', 32)
      svg.setAttribute('width', 32)
      svg.setAttribute('x', tX - 16)
      svg.setAttribute('y', tY - 44)
      textElement.setAttribute('y', tY + 12)
    },
    convertToDomNode (svgText) {
      const div = document.createElement('div')
      div.innerHTML = svgText
      return div.firstChild
    },
    getChartLabelElements () {
      return Array.from(this.$el.getElementsByClassName('apexcharts-data-labels'))
        .filter(element =>
          element.childElementCount > 0 && element.children[0].textContent.startsWith('#|')
        )
    },
    getChartXAxisLabel (item) {
      return `#|${item.linkClientId}|${item.count}`
    },
    getExamChartTooltip ({series, seriesIndex, dataPointIndex, w}) {
      return w.config.series[seriesIndex].data[dataPointIndex].tooltip
    }
  }
}
