import BaseMixin from './base.mixin'
import DateMixin from '@/shared/mixins/date.mixin'

const mixin = {
  mixins: [
    BaseMixin,
    DateMixin
  ],
  methods: {
    async getPendingExamsCount () {
      const url = '/core/Dashboard/CountPendingExams'
      const response = await this.getClient().get(url, {}, true)
      return response.data.data
    },
    async getPatientsCount () {
      const url = '/core/Dashboard/CountPatients'
      const response = await this.getClient().get(url, {}, true)
      return response.data.data
    },
    async getReportingStatsChartData (startDate, endDate) {
      startDate = this.toISOAsLocalDate(startDate)
      endDate = this.toISOAsLocalDate(endDate)
      const url = '/core/Dashboard/ReportingStatsChart'
      const response = await this.getClient().get(url, {
        params: {
          startDate,
          endDate
        }
      }, true)
      return response.data.data
    }
  }
}

export default mixin
