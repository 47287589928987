export default {
  name: 'item-list',
  components: {},
  props: {
    data: Array,
    hasCustomFilter: Boolean,
    customLength: Number | null,
    emptyMessage: String,
    showClick: Boolean | false
  },
  data () {
    return {

    }
  },
  computed: {
    getClassItem () {
      return this.showClick ? 'list-item pointer' : 'list-item'
    }
  },
  mounted () {

  },
  methods: {
    itemClick (item) {
      this.$emit('itemClick', item)
    }
  }
}
