import FormatMixin from '@/shared/mixins/format.mixin'
import CategoryMixin from '@/shared/mixins/category.mixin'
import ExamStatusMixin from '@/shared/mixins/exam-status.mixin'
import PhysicianListMixin from '@/shared/mixins/physician-list.mixin'

import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

export default {
  name: 'paciente-detalhes',
  components: {
    Popper
  },
  mixins: [
    FormatMixin,
    CategoryMixin,
    ExamStatusMixin,
    PhysicianListMixin
  ],
  props: [],
  data () {
    return {
      patient: {
        info: {},
        diagnosis: '',
        healthRecord: '',
        timeline: []
      },
      popperOptions: {
        placement: 'bottom'
      }
    }
  },
  computed: {

  },
  mounted () {
    this.loadPatient()
  },
  methods: {
    async loadPatient () {
      this.$root.$emit('showLoading')
      try {
        this.patient = await this.getPatientDetails(this.$route.params.id)
        this.patient.timeline = await Promise.all(this.patient.timeline.map(async (item) => {
          return {
            ...item,
            icon: await this.getIconByCategoryForMedicHeader(item)
          };
        }));
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    expandExam (item) {
      const routeData = this.$router.resolve({ name: 'paciente-exame', params: { patientId: this.$route.params.id, examId: item.examId } })
      window.open(routeData.href, '_blank')
    }
  }
}
